import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryJobArgs } from '../../generated/types';

export const GET_JOB = gql`
  query job($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $includeDeleted: Boolean) {
    job(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, includeDeleted: $includeDeleted) {
      uuid

      status
      feeling
      temperature

      isThroughAgency
      agencyName
      agentName
      agentEmail
      agentPhone
      referralFee

      company
      companyInfo
      companyWebsite
      companyLinkedIn
      companyAvatar
      companyLocation
      companyLocationCity
      companyLocationCountry
      companyLocationLatitude
      companyLocationLongitude
      companyLocationPostCode
      companyLocationStreet
      companyLocationUrl
      companyDistances

      jobTitle
      jobSeniorityLevel
      jobDescription
      jobRequirement
      jobUrl

      ir35
      duration
      rate
      employmentType
      remoteOption

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
        temporaryImageUrl
        isProcessed
        isConvertedToPDF
      }

      resumes {
        uuid
        state
        isNew
        isPublic
        isDeleted
        createdAt
        updatedAt
        createdBy {
          uuid
        }
        updatedBy {
          uuid
        }
      }

      events {
        uuid
        type
        startAt
        endAt
        isDeleted
        createdAt
        updatedAt
        meetingUrl
        location
        locationCity
        locationCountry
        locationLatitude
        locationLongitude
        locationPostCode
        locationStreet
        locationUrl
        description
        createdBy {
          uuid
          email
          nickname
          name
        }
        updatedBy {
          uuid
          email
          nickname
          name
        }
      }

      contacts {
        uuid
        fullName
        position
        email
        phoneNumber
        linkedin
        isDeleted
        createdAt
        avatar
        updatedAt
        createdBy {
          uuid
          email
          nickname
          name
        }
        updatedBy {
          uuid
          email
          nickname
          name
        }
      }

      notes {
        uuid
        title
        description
        isDeleted
        createdAt
        updatedAt
        createdBy {
          uuid
          email
          nickname
          name
        }
        updatedBy {
          uuid
          email
          nickname
          name
        }
      }

      tasks {
        uuid
        title
        description
        dueDate
        isFullDay
        priority
        isCompleted
        order
      }

      aiEmails {
        uuid
        category
        subject
        body
        createdAt
        updatedAt
        isDeleted
      }

      coverLetters {
        uuid
        state
        createdAt
        updatedAt
        isDeleted
      }

      isOwner
      owner {
        uuid
        email
        nickname
        name
      }

      createdBy {
        uuid
        email
        nickname
        name
      }

      updatedBy {
        uuid
        email
        nickname
        name
      }

      createdAt
      updatedAt
      appliedAt
      interviewedAt
      offerAt
      rejectedAt
      acceptedAt

      followers {
        uuid
        email
        nickname
        name
        avatar
      }

      permissions
      isPublic
      publicUrl

      isNew
      isDeleted

      aiIsProcessed
      aiIsPreparingCoverLetter
      aiMotivationLetters
      aiRequiredSkills
      aiSoftSkills
      aiNiceToHaveSkills

      aiMatchingRequiredSkills
      aiMatchingNiceToHaveSkills
      aiMatchingSoftSkills

      aiNonMatchingRequiredSkills
      aiNonMatchingNiceToHaveSkills
      aiNonMatchingSoftSkills

      aiMatchingRequiredSkillsPercentage
      aiMatchingNiceToHaveSkillsPercentage
      aiMatchingSoftSkillsPercentage
      aiMatchingSkillsPercentage
    }
  }
`;

export const useGetJob = (options?: QueryHookOptions<Pick<Query, 'job'>, QueryJobArgs>) =>
  useQuery<Pick<Query, 'job'>, QueryJobArgs>(GET_JOB, options);

export const useLazyGetJob = (options?: QueryHookOptions<Pick<Query, 'job'>, QueryJobArgs>) =>
  useLazyQuery<Pick<Query, 'job'>, QueryJobArgs>(GET_JOB, options);
